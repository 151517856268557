<template>
	<div class="box">
		<div class="box-top">
			<div>
				<span class="box-top-title">收货地址</span>
				<span> (地址最多10条，还能保存7条)</span>
			</div>
			<div class="addAddress" @click="tianjiaAdressDia">新建地址</div>
		</div>
		<div>
			<div class="box-item" v-for="item in dataList" :key="item.id">
				<div class="box-item-left">
					<div>
						<span class="box-item-leftTitle">收货人：</span>
						<span>{{item.receiver}}</span>
					</div>
					<div>
						<span class="box-item-leftTitle">联系方式：</span>
						<span>{{item.mobile}}</span>
					</div>
					<div>
						<span class="box-item-leftTitle">收货地址：</span>
						<span>{{item.addressFull}}</span>
					</div>
				</div>
				<div class="box-item-right">
					<div class="box-item-right-btn" v-if="item.isDefault==1">默认地址</div>
					<div class="box-item-right-btn1" v-else @click="setMore(item.id)">设为默认</div>
					<div class="box-item-right-text">
						<span @click="updateAdress(item)">编辑</span>
						<span @click="deleteAddress(item.id)">删除</span>
					</div>
				</div>
			</div>
			<el-empty v-if="dataList.length==0" description="暂无收货地址"></el-empty>
		</div>
		<!-- 修改地址彈框 -->
		<div class="updateAdressDia">
			<el-dialog @close="chooseupdateAdressDia" title="修改收货地址" :visible.sync="updateAdressDia" width="30%">
				<el-form :model="form" :rules="formRules">
					<el-form-item label="收货人姓名" :label-width="formLabelWidth" prop="receiver">
						<el-input v-model="form.receiver" placeholder="请输入收货人姓名"></el-input>
					</el-form-item>
					<el-form-item label="手机号码" :label-width="formLabelWidth" prop="mobile">
						<el-input v-model="form.mobile" placeholder="请输入手机号码"></el-input>
					</el-form-item>
					<el-form-item label="地址信息" :label-width="formLabelWidth" prop="Provinces">
						<el-cascader v-model="Provinces" placeholder="请选择省/市/区" @change="CityChange"
							:props="{ value: 'name', label: 'name', children: 'child' }" :options="cityOptions">
						</el-cascader>
					</el-form-item>
					<el-form-item label="详细地址" :label-width="formLabelWidth" prop="address">
						<el-input v-model="form.address" placeholder="请输入详细地址"></el-input>
					</el-form-item>
					<el-form-item label="邮政编码" :label-width="formLabelWidth">
						<el-input v-model="form.postalCode" placeholder="请输入邮政编码"></el-input>
					</el-form-item>
				</el-form>
				<div slot="footer" class="dialog-footer">
					<el-button @click="chooseupdateAdressDia">取 消</el-button>
					<el-button type="primary" @click="updateadresSubmit">
						提交</el-button>
				</div>
			</el-dialog>
		</div>
		<!-- 添加地址彈框 -->
		<div class="AdressDia">
			<el-dialog title="添加收货地址" :visible.sync="AdressDia" width="30%">
				<el-form :model="form" :rules="formRules">
					<el-form-item label="收货人姓名" :label-width="formLabelWidth" prop="receiver">
						<el-input v-model="form.receiver" placeholder="请输入收货人姓名"></el-input>
					</el-form-item>
					<el-form-item label="手机号码" :label-width="formLabelWidth" prop="mobile">
						<el-input v-model="form.mobile" placeholder="请输入手机号码"></el-input>
					</el-form-item>
					<el-form-item label="地址信息" :label-width="formLabelWidth" prop="address">
						<el-cascader v-model="Provinces" placeholder="请选择省/市/区" @change="CityChange"
							:props="{ value: 'name', label: 'name', children: 'child' }" :options="cityOptions">
						</el-cascader>
					</el-form-item>
					<el-form-item label="详细地址" :label-width="formLabelWidth" prop="address">
						<el-input v-model="form.address" placeholder="请输入详细地址"></el-input>
					</el-form-item>
					<el-form-item label="邮政编码" :label-width="formLabelWidth">
						<el-input v-model="form.postalCode" placeholder="请输入邮政编码"></el-input>
					</el-form-item>
					<el-form-item label="默认地址" :label-width="formLabelWidth">
						<el-switch v-model="isDefault" active-color="#66b1ff" active-value="1" inactive-value="0">
						</el-switch>
					</el-form-item>
				</el-form>
				<div slot="footer" class="dialog-footer">
					<el-button @click="AdressDia = false">取 消</el-button>
					<el-button type="primary" @click="tianjiaadresSubmit">
						添加</el-button>
				</div>
			</el-dialog>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				dataList:[],
				cityOptions: [],
				Provinces: [],
				isDefault: 0, //默认地址
				form: {
					// 地址标签邮编非必须
					id: "",
					isDefault: 0,
					receiver: "",
					mobile: "",
					province: "",
					city: "",
					district: "",
					address: "",
					postalCode: "",
					tag: "",
				},
				AdressDia: false,
				updateAdressDia: false,
				addAdressDia: false,
				formLabelWidth: "100px",
				updateAdressForm: {
					name: "",
				},
				formRules: {
					receiver: [{
						required: true,
						message: "请输入收货人姓名",
						trigger: "blur"
					}, ],
					mobile: [{
						required: true,
						message: "请输入手机号码",
						trigger: "blur"
					}, ],
					addressFull: [{
						required: true,
						message: "请输入详细地址",
						trigger: "blur"
					}, ],
					Provinces: [{
						required: true,
						message: "请输入地址信息",
						trigger: "blur"
					}, ],
					address: [{
						required: true,
						message: "请输入详细地址",
						trigger: "blur"
					}, ],
				},
			};
		},
		mounted() {
			this.getList();
		},
		methods: {
			getList() {
				this.$get('/user-api/mall/address/list').then(data => {
					if (data.code != 1000) {
						this.$message.info(data.msg);
						return
					}
					this.dataList = data.data;
				})
			},
			setMore(id){
				this.$post('/user-api/mall/address/default-set?id='+id).then(data=>{
					console.log(data)
					if (data.code != 1000) {
						this.$message.info(data.msg);
						return
					}
					this.$message.success("设置成功");
					this.getList();
				})
			},
			CityChange(value) {
				this.form.province = value[0];
				this.form.city = value[1];
				this.form.district = value[2];
			},
			// 添加地址弹框
			tianjiaAdressDia() {
				this.getProvince(0);
				this.form = {};
				this.AdressDia = true;
			},
			// 提交添加地址
			tianjiaadresSubmit() {
				// console.log(+this.isDefault,'hhj')
				this.form.isDefault = this.isDefault;
				this.$postJson("/user-api/mall/address/save-or-update", this.form).then(
					(data) => {
						if (data.code == 1000) {
							this.$message({
								message: "添加成功",
								type: "success",
							});
							this.AdressDia = false;
							this.getList();
						} else {
							this.$message(data.msg);
						}
					}
				);
			},
			// 修改地址
			updateAdress(e) {
				this.updateAdressDia = true;
				this.form = e;
				this.getProvince(1);
			},
			// 关闭修改地址弹窗
			chooseupdateAdressDia() {
				this.Provinces = [];
				// this.form = {};
				// this.form.province = '';
				// this.form.city ='';
				// this.form.district = '';
				this.updateAdressDia = false;
			},
			// 获取省市区地址
			getProvince(e) {
				// 获取省市区地址
				this.$get("base-api/area/tree").then((data) => {
					this.cityOptions = this.getTreeData(data.data);
					if(e==1){
						this.$nextTick(() => {
							let i, j, k;
							for (i = 0; i < this.cityOptions.length; i++) {
								if (this.cityOptions[i].name === this.form.province) {
									this.Provinces[0]=this.cityOptions[i].name
									break;
								}
							}
									
							for (j = 0; j < this.cityOptions[i].child.length; j++) {
								if (this.cityOptions[i].child[j].name === this.form.city) {
									this.Provinces[1]= this.cityOptions[i].child[j].name
									break;
								}
							}
							for (k = 0; k < this.cityOptions[i].child[j].child.length; k++) {
								if (
									this.cityOptions[i].child[j].child[k].name === this.form.district
								) {
									this.Provinces[2]= this.cityOptions[i].child[j].child[k].name
									break;
								}
							}
						});
					}
					console.log(this.Provinces)
				});
			},
			// 递归判断列表，把最后的children设为undefined
			getTreeData(data) {
				for (var i = 0; i < data.length; i++) {
					if (data[i].child.length < 1) {
						// children若为空数组，则将children设为undefined
						data[i].child = undefined;
					} else {
						// children若不为空数组，则继续 递归调用 本方法
						this.getTreeData(data[i].child);
					}
				}
				return data;
			},
			// 提交修改收货人地址
			updateadresSubmit() {
				this.$postJson("/user-api/mall/address/save-or-update", this.form).then(
					(data) => {
						if (data.code == 1000) {
							this.$message({
								message: "提交修改成功",
								type: "success",
							});
							this.updateAdressDia = false;
							this.getList();
						} else {
							this.$message(data.msg);
						}
					}
				);
			},
			//删除地址
			deleteAddress(id) {
				this.$post("/user-api/mall/address/delete", {
					id: id
				}).then((data) => {
					if (data.code === 1000) {
						this.$message({
							message: "删除成功",
							type: "success",
						});
						this.getList()
					} else {
						this.$message(data.message);
					}
				});
			},
		}
	};
</script>

<style scoped>
	.box {
		padding: 20px;
	}

	.box-top {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
	}

	.box-top-title {
		font-size: 14px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #262626;
		line-height: 52px;
	}
	.addAddress{
		padding: 0 10px;
		background-color: #4A99F6;
		text-align: center;
		color: #FFFFFF;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 400;
		line-height: 30px;
		border-radius: 3px;
	}

	.box-item {
		border: 1px solid #DDDDDD;
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-direction: row;
		padding: 20px;
		margin-bottom: 20px;
	}

	.box-item-left {
		flex: 1;
	}

	.box-item-leftTitle {
		font-size: 12px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 500;
		color: #8a8a8a;
		line-height: 32px;
	}

	.box-item-right {
		width: 200px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	.box-item-right-btn {
		padding: 0 10px;
		background-color: #4A99F6;
		text-align: center;
		color: #FFFFFF;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 400;
		line-height: 30px;
		border-radius: 3px;
	}
	.box-item-right-btn1{
		padding: 0 10px;
		text-align: center;
		color: #4A99F6;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 400;
		line-height: 30px;
		border-radius: 3px;
		border: 1px solid #4A99F6;
		cursor: pointer;
	}
	

	.box-item-right-text {
		margin-top: 10px;
	}

	.box-item-right-text>span {
		padding: 5px;
		color: #4A99F6;
		cursor: pointer;
	}
</style>
